
export default {

    getHoursMinutesDifference: (state) => (departure_date_utc, arrival_date_utc) => {
        const departureTime = state.moment(departure_date_utc);
        const arrivalTime = state.moment(arrival_date_utc);

        const differenceMinutes = arrivalTime.diff(departureTime, 'minutes');

        const hours = Math.floor(differenceMinutes / 60);
        const minutes = differenceMinutes % 60;

        return `${hours}h ${minutes}m`;
    },


    // getPassengerServiceAdded: (state) => (journeyId, passengerId) => {

    //     // let journey = state.cartItems.find(cartItem => cartItem.id === journeyId);

    //     // let passengerItems = [];
    //     // if(journey){
    //     //     journey.addedServices.forEach(service => {
    //     //         let passengerFound = service.addedPassengers.findIndex(addedPassenger => addedPassenger.id === passengerId);
    //     //         if (passengerFound !== -1) { 
    //     //             passengerItems.push(service);
    //     //         }
    //     //       });
    //     // }

    //     // return passengerItems;

    //     // if (journeyIndex !== -1) {
    //     //     let serviceIndex = state.cartItems[journeyIndex].addedServices.findIndex(addedService => addedService.type === service.type);
    //     //     if (serviceIndex !== -1) {
    //     //       let passengerIndex = state.cartItems[journeyIndex].addedServices[serviceIndex].addedPassengers.findIndex(addedPassenger => addedPassenger.id === passenger.id);
    //     //       if (passengerIndex === -1) {
    //     //         state.cartItems[journeyIndex].addedServices[serviceIndex].addedPassengers.push(passenger);
    //     //       }
    //     //     } else {
    //     //       let addedService = {
    //     //         ...service,
    //     //         addedPassengers: [{
    //     //           ...passenger
    //     //         }]
    //     //       };
    //     //       state.cartItems[journeyIndex].addedServices.push(addedService)
    //     //     }
    //     //   } else {
    //     //     const cartItem = {
    //     //       ...journey,
    //     //       addedServices: [{
    //     //         ...service,
    //     //         addedPassengers: [{
    //     //           ...passenger
    //     //         }]
    //     //       }]
    //     //     };
    //     //     state.cartItems.push(cartItem);
    //     //   }

    //     //   console.log(state.cartItems)

    // //     return `<div class="service-added">
    // //     <span class="service-icon"><img src="../assets/images/services/check-in.svg" alt="service"></span>
    // //     <span class="service-added-text">Check in added</span>
    // //     <span class="service-added-price" :style="{ color:  data.brand.background_color }">5€</span>
    // //   </div>`;
    // },

    totalPriceWithVat: (state, getters) => {
        if (state.tatalPrice > 0) {
            // let totalPriceWithVat = state.tatalPrice + (state.tatalPrice * state.data.vat)
            // let totalPiceWithVatFormated = Math.round((totalPriceWithVat + 0.005) * 100) / 100;
            return  getters.formatCurrency(state.tatalPrice)
        }
        return 0
    },

    getServicePassengersPrice: (state, getters) => (servicePassengers) => {
        let total = 0;
        servicePassengers.forEach(servicePassenger => {
            total += servicePassenger.price;
        });

        return getters.formatCurrency(total);
    },

    disableCheckout: (state) => {
        return !state.journeys.some(journey =>
          journey.passengers.some(passenger => {
            for (const service of passenger.services) {
              if (!service.paid) {
                return true; 
              }
            }
          })
        );
      },

    
    addedAllServicePassenger: (state) => (passengers) => {
        return !passengers.some(passenger => passenger.added === false);
    },

    formatCurrency: (state, getters) => {
      return (number) => {
        return state.data.currency.symbol + ' ' +  number.toFixed(2).replace('.', ',')
      }
    },

}