<template>

  <div v-if="loading" class="text-center mt-5">

    <div class="spinner-border" role="status">
    </div>
  </div>
  <div v-else>
    <template v-if="error">
    <div class="d-flex align-items-center justify-content-center vh-100">
      <div class="text-center">
        <h1 class="display-2 fw-bold">404</h1>
        <p class="fs-3">
          <span class="text-danger">Opps!</span> Not found.
        </p>
      </div>
    </div>
    </template>

    <template v-else>
      <nav class="navbar" :style="{ background: data.brand.whitelabel_config.headerBackgroundColor }">
        <div class="container-fluid">
          <span class="navbar-image"><img v-if="data.brand.whitelabel_config.header_logo !== null"
              :src="apiUrlStorage + data.brand.whitelabel_config.header_logo" width="120" />
          </span>
        </div>
      </nav>

      <div id="seat-selector-container"></div>
      <button id="open-seat-selector-link" href="javascript:void(0);" hidden></button>

      <template v-if="deviceType == 'desktop'">
        <div class="ancillaries-container"
          :style="{ background: data.brand.whitelabel_config.mainBackgroundColor, fontFamily: data.brand.whitelabel_config.fontFamily }">
          <div class="ancillaries-desktop">
            <template v-if="step === 0">
              <template v-if="selectedJourneyIndex == null">
                <AncillaryFlightsContainerViewD :data="data" :journeys="journeys" :moment="moment"
                  :showItemsAsIcon="showItemsAsIcon" :selectedJourneyIndex="selectedJourneyIndex" :apiAirportUrl="apiAirportUrl">
                </AncillaryFlightsContainerViewD>
              </template>
              <template v-else>
                <AncillaryFlightViewD :data="data" :journeys="journeys" :moment="moment"
                  :selectedJourney="journeys[selectedJourneyIndex]" :selectedJourneyIndex="selectedJourneyIndex"
                  :disableCheckout="disableCheckout" :nextStep="nextStep" :apiAirportUrl="apiAirportUrl"></AncillaryFlightViewD>
              </template>
            </template>
            <template v-else-if="step === 1">
              <YourCartViewD :data="data" :cartItems="cartItems" :disableCheckout="disableCheckout"
                :nextStep="nextStep" />
            </template>
            <div v-else-if="step === 2 && !loadingStripe">
              <StripeViewD :data="data" />
            </div>
            <div v-else-if="step === 3">
              <ThankYouViewD :data="data" />
            </div>
          </div>

          <FooterViewD :data="data" :apiUrlStorage="apiUrlStorage" />
        </div>

      </template>



      <template v-else-if="deviceType == 'mobile'">
        <div class="ancillaries-container"
          :style="{ background: data.brand.whitelabel_config.mainBackgroundColor, fontFamily: data.brand.whitelabel_config.fontFamily }">
          <div v-if="step === 2 && !loadingStripe">
            <StripeView :data="data" />
          </div>
          <div v-else-if="step === 3">
            <ThankYouView :data="data" />
          </div>

          <div v-show="step === 0 || step === 1">
            <!-- <div id="seat-selector-container"></div>
        <button id="open-seat-selector-link" href="javascript:void(0);" hidden></button> -->
            <div class="ancillaries">
              <div v-show="step === 0">

                <AncillaryFlightView :data="data" :journeys="journeys" :moment="moment" :apiAirportUrl="apiAirportUrl" />
              </div>
              <div v-if="step === 1">
                <YourCartView :data="data" :cartItems="cartItems" />
              </div>
            </div>


            <nav class="checkout-container" :style="{ background: data.brand.whitelabel_config.mainBackgroundColor }">
              <div class="container-fluid row">
                <div class="col-1">
                </div>
                <div class="col-10 p-0">
                  <button class="checkout" @click="nextStep()" :disabled="disableCheckout"
                    :style="{ background: data.brand.whitelabel_config.buttonBackgroundColor, color: data.brand.whitelabel_config.buttonTextColor, border: data.brand.whitelabel_config.buttonBackgroundColor }">{{
                      data.text.checkout }}</button>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </template>
    </template>

  </div>


</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import AncillaryFlightView from "./AncillaryFlightView.vue";
import YourCartView from "./AncillaryCartView.vue";
import StripeView from "./StripeView.vue";
import ThankYouView from "./ThankYouView.vue";

import AncillaryFlightsContainerViewD from "./desktop/AncillaryFlightsContainerView.vue";

import AncillaryFlightViewD from "./desktop/AncillaryFlightView.vue";
import YourCartViewD from "./desktop/AncillaryCartView.vue";
import StripeViewD from "./desktop/StripeView.vue";
import ThankYouViewD from "./desktop/ThankYouView.vue";
import FooterViewD from "./desktop/FooterView.vue";


export default {
  components: {
    AncillaryFlightView,
    YourCartView,
    StripeView,
    ThankYouView,

    AncillaryFlightsContainerViewD,
    AncillaryFlightViewD,
    YourCartViewD,
    StripeViewD,
    ThankYouViewD,
    FooterViewD
  },

  async mounted() {
    await this.loadData({
      id: this.$route.params.id,
    });

    if (this.data && this.data.ancillary_token !== null) {
      await this.loadSeat();
    }

    window.addEventListener('resize', this.reloadPage);
  },

  methods: {
    ...mapActions(['loadData', 'loadSeat', 'nextStep', 'reloadPage']),
  },

  computed: {
    ...mapGetters(['disableCheckout']),
    ...mapState(['loading', 'data', 'journeys', 'moment', 'cartItems', 'apiUrlStorage', 'step', 'loadingStripe', 'deviceType', 'showItemsAsIcon', 'selectedJourneyIndex', 'apiAirportUrl', 'error']),
  },
};
</script>

<style scoped>
.checkout-container .container-fluid {
  margin: 0px;
  padding: 0px;
}

.checkout-container .checkout {
  /* font-family: Figtree; */
  font-size: 12px;
  font-weight: 800;
  line-height: 14.4px;
  width: 100%;
  text-align: center;
  height: 50px;
  border-radius: 18px;
  margin: 0px;
  padding: 0px;
  text-transform: uppercase;
}

.checkout-container {
  height: 80px;
  /* background-color: #F1F1F1 !important; */
  position: sticky;
  bottom: 0;
  z-index: 999;
}

.navbar {
  border-bottom: 0.5px solid;
  /* height: 80px; */
  /* color: #000000; */
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar .navbar-image {
  width: 100%;
  text-align: center;

}

.navbar .navbar-image img {
  height: 100px;
  width: auto;
  object-fit: cover
}

.ancillaries {
  /* background: #F1F1F1; */
  min-height: calc(100vh - 80px);
  padding: 0px;
  overflow-x: hidden;
}

.checkout:disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.ancillaries-desktop {
  min-height: calc(100vh - 405px);
  padding: 0px;
  overflow-x: hidden;
}
</style>