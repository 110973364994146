<template>

    <div class="row">
        <div class="col-1">
        </div>
        <div class="col-10">
            <div class="svg-knob-elevation-and-text">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.603448" y="0.603448" width="23.7931" height="23.7931" rx="9.39655" :stroke="data.brand.whitelabel_config.fontColor"
                        stroke-width="1.2069" />
                    <path
                        d="M14 17.75C13.7891 17.75 13.6016 17.6797 13.4609 17.5391L8.96094 13.0391C8.65625 12.7578 8.65625 12.2656 8.96094 11.9844L13.4609 7.48438C13.7422 7.17969 14.2344 7.17969 14.5156 7.48438C14.8203 7.76562 14.8203 8.25781 14.5156 8.53906L10.5547 12.5L14.5156 16.4844C14.8203 16.7656 14.8203 17.2578 14.5156 17.5391C14.375 17.6797 14.1875 17.75 14 17.75Z"
                        :fill="data.brand.whitelabel_config.fontColor" />
                </svg>
                <p class="ready-for-your-trip-text mb-0" :style="{ color: data.brand.whitelabel_config.fontColor }">{{ data.text.lets_get_ready_for_your_trip }}</p>
            </div>
        </div>
    </div>



    <div id="flightCarousel" class="carousel slide"   data-bs-ride="carousel" data-bs-interval="5000000">
        <div class="carousel-indicators carousel-flights">
                <template v-for="(journey, journeyIndex) in journeys" :key="journeyIndex">
                    <div data-bs-target="#flightCarousel"
                        :data-bs-slide-to="`${journeyIndex}`"
                        :class="{ 'active': journeys[0].id == journey.id }"
                        :style="{'--activeCardBackgroundColor': data.brand.whitelabel_config.buttonBackgroundColor, '--inactiveCardBackgroundColor': data.brand.whitelabel_config.cartBackgroundColor}"
                        >
                    </div>
            </template>
        </div>
        <div class="carousel-inner">
                <template v-for="(journey, journeyIndex) in journeys" :key="journeyIndex">
                    <div class="carousel-item" :class="journeys[0].id == journey.id ? 'active' : ''">
                        <div class="flight-cart-container row">
                            <div class="col-1 p-0">
                                <div class="flight-left-cart" data-bs-target="#flightCarousel"
                                    data-bs-slide="prev" :style="{ background: data.brand.whitelabel_config.cartBackgroundColor }">
                                </div>
                            </div>
                            <div class="col-10 p-0">
                                <div class="flight-cart card" :style="{ background: data.brand.whitelabel_config.cartBackgroundColor }">
                                    <img class="city-image" :src="`${apiAirportUrl}${journey.arrival_airport_image}`"/>
                                  
                                    <div class="flight-info-image">
                                        <div class="row">
                                            <div class="col-9 ps-0">
                                                <button class="airline-info" :style="{ background: data.brand.whitelabel_config.fontColor, color: data.brand.whitelabel_config.cartBackgroundColor  }">{{journey.airline_name}} {{journey.airline}}{{journey.flight_number}}</button>
                                            </div>
                                            <div class="col-3 cart-total-users"  data-bs-toggle="collapse"
                                                :href="`#passengerCollapse${journeyIndex}`" aria-expanded="true">
                                                <svg width="13" height="14" viewBox="0 0 13 14" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M6.3894 7.125C4.58667 7.125 3.1394 5.67773 3.1394 3.875C3.1394 2.09766 4.58667 0.625 6.3894 0.625C8.16675 0.625 9.6394 2.09766 9.6394 3.875C9.6394 5.67773 8.16675 7.125 6.3894 7.125ZM8.64917 7.9375C10.5281 7.9375 12.0769 9.48633 12.0769 11.3652V12.4062C12.0769 13.0918 11.5183 13.625 10.8582 13.625H1.92065C1.23511 13.625 0.701904 13.0918 0.701904 12.4062V11.3652C0.701904 9.48633 2.22534 7.9375 4.10425 7.9375H4.53589C5.09448 8.2168 5.72925 8.34375 6.3894 8.34375C7.04956 8.34375 7.65894 8.2168 8.21753 7.9375H8.64917Z"
                                                        :fill="data.brand.whitelabel_config.fontColor" />
                                                </svg>
                                                <span class="total-users" :style="{ color: data.brand.whitelabel_config.fontColor}">{{data.payload.passengers.length}}</span>
                                                <div class="chevron-down">
                                                    <svg width="11" height="7" viewBox="0 0 11 7" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M5.1017 6.45337L0.531385 1.90649C0.320448 1.67212 0.320448 1.32056 0.531385 1.10962L1.07045 0.570557C1.28139 0.359619 1.63295 0.359619 1.86732 0.570557L5.50014 4.17993L9.10951 0.570557C9.34389 0.359619 9.69545 0.359619 9.90639 0.570557L10.4454 1.10962C10.6564 1.32056 10.6564 1.67212 10.4454 1.90649L5.87514 6.45337C5.6642 6.66431 5.31264 6.66431 5.1017 6.45337Z"
                                                            :fill="data.brand.whitelabel_config.fontColor" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flight-info">
                                        <div class="row">
                                            <div class="col-12 p-0"><span class="confirmation-code" :style="{ color: data.brand.whitelabel_config.fontColor2}">
                                                    {{data.text.confirmation_code}} {{journey.pnr }}</span>
                                            </div>
                                        </div>
                                        <div class="row flight-city-info" :style="{ color: data.brand.whitelabel_config.fontColor2}">
                                            <div class="col-6  ps-0">
                                                <span class="flight-city">{{journey.departure_airport_name}}</span>
                                            </div>
                                            <div class="col-6 text-end pe-0">
                                                <span class="flight-city">{{journey.arrival_airport_name}}</span>
                                            </div>
                                        </div>
                                        <div class="row airport-iata-info" :style="{ color: data.brand.whitelabel_config.fontColor}">
                                            <div class="col-4  ps-0">
                                                <span class="airport-iata">{{journey.departure_airport}}</span>
                                            </div>
                                            <div class="col-4 d-flex align-items-center justify-content-center">
                                                <svg width="27" height="27" viewBox="0 0 27 27" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M21.9725 10.5C23.4999 10.5 26.1952 11.8027 26.1952 13.375C26.1952 14.9922 23.4999 16.25 21.9725 16.25H16.7167L12.2245 24.1562C11.955 24.6055 11.4608 24.875 10.9667 24.875H8.45105C7.95691 24.875 7.59753 24.4258 7.7323 23.9766L9.93347 16.25H5.35144L3.37488 18.8555C3.24011 19.0352 3.06042 19.125 2.83582 19.125H0.949097C0.589722 19.125 0.32019 18.8555 0.32019 18.4961C0.32019 18.4512 0.32019 18.4062 0.32019 18.3613L1.75769 13.375L0.32019 8.43359C0.32019 8.38867 0.32019 8.34375 0.32019 8.25391C0.32019 7.93945 0.589722 7.625 0.949097 7.625H2.83582C3.06042 7.625 3.24011 7.75977 3.37488 7.93945L5.35144 10.5H9.93347L7.7323 2.81836C7.59753 2.36914 7.95691 1.875 8.45105 1.875H10.9667C11.4608 1.875 11.955 2.18945 12.2245 2.63867L16.7167 10.5H21.9725Z"
                                                        :fill="data.brand.whitelabel_config.fontColor" />
                                                </svg>
                                            </div>
                                            <div class="col-4 text-end pe-0">
                                                <span class="airport-iata">{{journey.arrival_airport}}</span>
                                            </div>
                                        </div>
                                        <div class="row departure-date-info" :style="{ color: data.brand.whitelabel_config.fontColor}">
                                            <div class="col-5  ps-0">
                                                <span class="date"> {{ moment( journey.departure_date_utc).format("MMM D, h:mm A")  }}</span>
                                            </div>
                                            <div class="col-2 d-flex align-items-center justify-content-center p-0">
                                                <span class="date">{{getHoursMinutesDifference(journey.departure_date_utc, journey.arrival_date_utc)}}</span>
                                            </div>
                                            <div class="col-5 text-end pe-0">
                                                <span class="date arrival-date">{{ moment( journey.arrival_date_utc).format("MMM D, h:mm A")  }}</span>
                                            </div>
                                        </div>
                                        <div class="row passenger-info collapse show" :id="`passengerCollapse${journeyIndex}`">
                                            <div class="col-12 passengers-names" :style="{ color: data.brand.whitelabel_config.fontColor2}">
                                                 {{ data.text.passengers_names }} 
                                            </div>
                                            <template v-for="(passenger, passengerIndex) in journey.passengers" :key="passengerIndex">
                                                <div class="row passenger-name-container">
                                                    <div class="col-7 passenger-name" :style="{ color: data.brand.whitelabel_config.fontColor}">
                                                          {{ passenger.name }} {{ passenger.last_name }}
                                                    </div>
                                                    <div class="col-5 passenger-no-service-added" v-if="passenger.services.length == 0" :style="{ color: data.brand.whitelabel_config.fontColor2}">
                                                        {{ data.text.no_service_added }} 
                                                    </div>

                                                    <div  class="service-added" v-for="(passengerService, passengerServiceIndex) in passenger.services" :key="passengerServiceIndex">
                                                        <span class="service-icon">
                                                            <template v-if="passengerService.type == 'check-in'">
                                                                <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0 1.5C0 0.679688 0.65625 0 1.5 0H12C12.8203 0 13.5 0.679688 13.5 1.5V3.375C12.8672 3.375 12.375 3.89062 12.375 4.5C12.375 5.13281 12.8672 5.625 13.5 5.625V7.5C13.5 8.34375 12.8203 9 12 9H1.5C0.65625 9 0 8.34375 0 7.5V5.625C0.609375 5.625 1.125 5.13281 1.125 4.5C1.125 3.89062 0.609375 3.375 0 3.375V1.5Z" :fill="data.brand.whitelabel_config.fontColor2"/>
                                                                    </svg>
                                                                </template>
                                                                <template v-else-if="passengerService.type == 'seat-selection'">
                                                                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M13.875 4.75C14.4844 4.75 15 5.26562 15 5.875V10.375C15 10.5859 14.8125 10.75 14.625 10.75H13.125C12.9141 10.75 12.75 10.5859 12.75 10.375V10H2.25L2.22656 10.375C2.22656 10.5859 2.0625 10.75 1.875 10.75H0.375C0.164062 10.75 0 10.5859 0 10.375V5.875C0 5.26562 0.492188 4.75 1.125 4.75C1.73438 4.75 2.25 5.26562 2.25 5.875V7.75H12.75V5.875C12.75 5.26562 13.2422 4.75 13.875 4.75ZM3 5.875C3 4.98438 2.34375 4.23438 1.5 4.04688L1.47656 3.25C1.47656 1.60938 2.8125 0.25 4.47656 0.25H10.5C12.1406 0.25 13.5 1.60938 13.5 3.25V4.04688C12.6328 4.23438 12 4.98438 12 5.875V7H3V5.875Z" :fill="data.brand.whitelabel_config.fontColor2"/>
                                                                    </svg>

                                                                </template>
                                                                <template v-else-if="passengerService.type == 'fast-track'">
                                                                    <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.42969 4.55469C9.52344 4.74219 9.5 4.97656 9.38281 5.14062L4.50781 12.2656C4.41406 12.4297 4.22656 12.5 4.0625 12.5C3.96875 12.5 3.89844 12.5 3.82812 12.4531C3.57031 12.3359 3.45312 12.0781 3.5 11.8203L4.48438 7.25H1.0625C0.898438 7.25 0.734375 7.17969 0.617188 7.0625C0.523438 6.94531 0.476562 6.78125 0.5 6.61719L1.25 0.992188C1.27344 0.710938 1.50781 0.5 1.8125 0.5H5.9375C6.10156 0.5 6.28906 0.59375 6.38281 0.757812C6.5 0.921875 6.52344 1.10938 6.45312 1.27344L5.25781 4.25H8.9375C9.125 4.25 9.33594 4.36719 9.42969 4.55469Z" :fill="data.brand.whitelabel_config.fontColor2"/>
                                                                    </svg>

                                                                </template>
                                                        </span>
                                                        <span class="service-added-text" :style="{ color: data.brand.whitelabel_config.fontColor2}">{{ data.serviceTexts[passengerService.type].name }}</span>
                                                        <span class="service-added-price" :style="{ color:  data.brand.whitelabel_config.buttonBackgroundColor }">{{formatCurrency(passengerService.price)}}   {{ passengerService.paid ? data.text.paid : '' }}</span>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1 p-0 text-end" data-bs-target="#flightCarousel"
                                data-bs-slide="next">
                                <div class="flight-right-cart" :style="{ background: data.brand.whitelabel_config.cartBackgroundColor }">

                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-1">
                            </div>
                            <div class="col-10">
                                <div class="select-services-text" :style="{ color: data.brand.whitelabel_config.fontColor}">
                                    {{ data.text.select_services }} 
                                </div>
                            </div>
                        </div>

                        <div class="row select-services">
                            <div class="col-1">
                            </div>
                            <div class="col-10 row m-0 p-0">
                                <template v-for="(service, serviceIndex) in journey.services" :key="serviceIndex"> 
                                    <div class="col-12"  v-if="!addedAllServicePassenger(service.passengers)">
                                        <template v-if="service.type == 'seat-selection'"> 
                                            <template v-if="data.displaySeatMap">
                                                <div @click="openSeatMap()">
                                                <div class="image-service-container">
                                                    <img class="image-service" src="../assets/images/flight.jpg" alt="service">
                                                    <div class="service-price-container"
                                                        :style="{ background: data.brand.whitelabel_config.cartBackgroundColor }">
                                                        <span class="service-price"
                                                            :style="{ color: data.brand.whitelabel_config.buttonBackgroundColor }">
                                                            {{ data.serviceTexts[service.type].starting_from }}</span>
                                                    </div>
                                                    <span class="service-type" :style="{ color: data.brand.whitelabel_config.cartBackgroundColor }"> 
                                                        {{ data.serviceTexts[service.type].name }}
                                                    </span>
                                                </div>
                                                <div class="service-description" :style="{ color: data.brand.whitelabel_config.fontColor}">
                                                    {{  data.serviceTexts[service.type].description }}
                                                </div>
                                                
                                                        <button  class="add-service" 
                                                        :style="{
                                                            backgroundColor:  data.brand.whitelabel_config.mainBackgroundColor,
                                                            color: data.brand.whitelabel_config.fontColor,
                                                            border: '1.21px solid ' + data.brand.whitelabel_config.fontColor
                                                        }">
                                                            {{ data.text.add_service }}
                                                        </button>
                                                    </div>
                                            </template>
                                        </template>

                                        <template v-else> 
                                            <div data-bs-toggle="modal" :data-bs-target="`#addPassengerModal${journeyIndex}${serviceIndex}`">
                                                <div class="image-service-container">
                                                    <img class="image-service" src="../assets/images/flight.jpg" alt="service">
                                                    <div class="service-price-container"
                                                        :style="{ background: data.brand.whitelabel_config.cartBackgroundColor }">
                                                        <span class="service-price"
                                                            :style="{ color: data.brand.whitelabel_config.buttonBackgroundColor }">{{formatCurrency(service.price)}}</span>
                                                    </div>
                                                    <span class="service-type" :style="{ color: data.brand.whitelabel_config.cartBackgroundColor }"> 
                                                        {{ data.serviceTexts[service.type].name }}
                                                    </span>
                                                </div>
                                                <div class="service-description" :style="{ color: data.brand.whitelabel_config.fontColor}">
                                                    {{  data.serviceTexts[service.type].description }}
                                                </div>
                                                <button class="add-service" :style="{
                                                            backgroundColor:  data.brand.whitelabel_config.mainBackgroundColor,
                                                            color: data.brand.whitelabel_config.fontColor,
                                                            border: '1.21px solid ' + data.brand.whitelabel_config.fontColor
                                                        }">
                                                    {{ data.text.add_service }}
                                                </button>
                                            </div>
                                        </template>

                                        </div>
                                    
                                    <div class="col-12" v-else >
                                        <div class="image-service-container">
                                            <img class="image-service" src="../assets/images/flight.jpg" alt="service" :style="{border: '2px solid ' + data.brand.whitelabel_config.buttonBackgroundColor}">
                                            <div class="service-checked-container"  :style="{ background: data.brand.whitelabel_config.buttonBackgroundColor }">
                                                <span class="service-checked">
                                                    <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M20.63 0.610001C21.215 1.15 21.215 2.095 20.63 2.635L9.11 14.155C8.57 14.74 7.625 14.74 7.085 14.155L1.325 8.395C0.74 7.855 0.74 6.91 1.325 6.37C1.865 5.785 2.81 5.785 3.35 6.37L8.075 11.095L18.605 0.610001C19.145 0.0250007 20.09 0.0250007 20.63 0.610001Z" fill="white"/>
                                                    </svg>
                                                </span>
                                            </div>
                                            <span class="service-type" :style="{ color: data.brand.whitelabel_config.cartBackgroundColor }"> {{data.serviceTexts[service.type].name}}</span>
                                        </div>
                                        <div class="service-description">
                                            {{  data.serviceTexts[service.type].description }}
                                        </div>
                                        <button class="add-service" :style="{
                                                            backgroundColor:  data.brand.whitelabel_config.mainBackgroundColor,
                                                            color: data.brand.whitelabel_config.fontColor2,
                                                            border: '1.21px solid ' + data.brand.whitelabel_config.fontColor2
                                                        }">
                                             {{ data.text.added }}
                                        </button>
                                    </div>
                                    <AddPassengerModalView :data="data" :journeyIndex="journeyIndex" :service="service" :serviceIndex="serviceIndex"/>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
        </div>
    </div>
</template>

<script>
import AddPassengerModalView from "./AddPassengerModalView.vue";
import { mapActions, mapGetters } from "vuex";
export default {
    components: {
        AddPassengerModalView,
    },

    props: ['data', 'journeys', 'moment', 'apiAirportUrl'],

    computed: {
        ...mapGetters(['getHoursMinutesDifference', 'addedAllServicePassenger', 'formatCurrency']),
    },

    mounted() {
        document.querySelector('.carousel-indicators div').click();
    },

    methods: {

        // addedToCart(id) {
        //     return this.cartItems.find(cartItem => cartItem.id == id);
        // },

        ...mapActions(['openSeatMap']),
    },


}
</script>

<style scoped>
.city-image {
    width: 100%;
    height: 113px;
    object-fit: cover;
    filter: brightness(0.8);
}

.flight-info-image{
    padding: 0px 34px 0px 34px;
}

.flight-info-image .airline-info{
    position: absolute;
    top: 18.83px;
}

.flight-info-image .cart-total-users{
    position: absolute;
    top: 18.83px;
    right: 16px;
}

.service-added {
  display: flex;
  align-items: center;
  padding: 0px;
  height:  27px;
  /* border-bottom: 0.5px solid #999999 */
}

.service-icon {
  display: flex;
  align-items: center;
  margin-right: 10px;  
}

.service-added-price {
  margin-left: auto;  
  /* font-family: Figtree; */
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
}

.service-added-text {
    /* font-family: Figtree; */
    font-size: 12px;
    font-weight: 700;
    line-height: 14.4px;
    text-align: left;
    /* color: #4C4C4C; */
}

.service-checked{
    text-align: center;
    position: absolute;
    line-height: 16.8px;
    max-height: 70px;
    bottom: 17px;
    left: 0px;
    width: 50px;
}

.service-checked-container{
    position: absolute;
    top: -8px;
    margin-left: 51.13px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 49px;
    right: -14px;
}

.cart-total-users[aria-expanded="false"] .chevron-down svg {
    transform: rotate(180deg);
}
 

.carousel-flights{
    margin-top: 17px;
}

.carousel-indicators{
    margin-bottom:unset;
}
.carousel-indicators div {
  border: unset;
  border-top: unset;
  border-bottom: unset;
  opacity: unset;
}

.carousel-flights div{
    width: 12px;
    height: 6px;
    border-radius: 100px;
    background-color: var(--inactiveCardBackgroundColor);
}

.carousel-flights .active{
    width: 24px;
    background-color: var(--activeCardBackgroundColor);
}

.carousel-indicators {
    position: unset;
}

.passenger-no-service-added {
    /* font-family: Figtree; */
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: right;
    /* color: #4C4C4C; */
    padding: 0px;


}

.passenger-name-container {
    margin: 10px 0px 20px 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.passenger-name {
    /* font-family: Figtree; */
    font-size: 14px;
    font-weight: 800;
    line-height: 16.8px;
    text-align: left;
    /* color: #000000; */
    padding-left: 0px;
    margin-bottom: 10px;


}

.passengers-names {
    margin-top: 29px;
    /* font-family: Figtree; */
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: left;
    padding-left: 0px;
    /* color: #4C4C4C; */


}

.departure-date-info{
    margin-bottom: 20px;
}
 

.add-service {

    width: 100%;
    height: 35px;
    border-radius: 12.07px;
    /* border: 1.21px solid #000000;
    color: #000000; */
    /* font-family: Figtree; */
    font-size: 12px;
    font-weight: 800;
    line-height: 14.4px;
    text-align: center;
    margin: 16px 0px 16px 0px;


}

.service-description {
    /* font-family: Figtree; */
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: left;
    /* color: #000000; */
    margin-top: 16px;
    /* width: 90px; */

}

.service-type {
    top: 70px;
    width: 80%;
    position: absolute;
    /* font-family: Figtree; */
    font-size: 14px;
    font-weight: 800;
    line-height: 16.8px;
    text-align: left;
    max-height: 70px;
    margin-left: 10.13px;
    bottom: 10px;
    left: 0px;
    /* width: 90px; */
}

.service-price {
    /* font-family: Figtree; */
    font-size: 12px;
    font-weight: 800;
    line-height: 14.4px;
    padding: 0px 10px 0px 10px
}

.image-service-container {
    position: relative;
}

.service-price-container {
    position: absolute;
    /* width: 51.71px; */
    height: 29.71px;
    top: 10px;
    margin-left: 10.13px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.image-service {
    width: 100%;
    height: 101.32px;
    border-radius: 15.54px;
    object-fit: cover; 
    /* filter: grayscale(100%); */
    
}


.select-services {
    margin-top: 16px;
}

.select-services-text {
    /* font-family: Figtree; */
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    /* color: #000000; */
    /* margin-top: 46px; */
    margin-top: 26px;

}


.departure-date-info span.arrival-date {
    justify-content: right;
}

.departure-date-info span.date {
    display: flex;
    align-items: center;
}

.departure-date-info .date {
    /* font-family: Figtree; */
    font-size: 12px;
    font-weight: 700;
    line-height: 14.4px;
    /* color: #000000; */
}

.airport-iata {
    /* font-family: Figtree; */
    font-size: 35px;
    font-weight: 600;
    line-height: 42px;
    text-align: left;
    /* color: #000000; */
}

.flight-city-info {
    margin-top: 10px;
}

.flight-city {
    /* font-family: Figtree; */
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    /* color: #4C4C4C; */
}

.confirmation-code{
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    text-transform: capitalize;
}

.svg-knob-elevation-and-text {
    margin-top: 30px;
    display: inline-flex;
}

.ready-for-your-trip-text {
    padding-left: 16px;
    /* font-family: Figtree; */
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    display: flex;
    align-items: center;
    /* color: #000000; */
}

.flight-cart-container {
    margin: 15px 0px 0px 0px;
}

.flight-left-cart {
    margin-top: 11px;
    height: 163px;
    border-radius: 0px 22px 22px 0px;
    /* background-color: #ffffff; */
    margin-right: 8px;
}

.flight-cart {
    min-height: 185px;
    border-radius: 25px;
    overflow: hidden;
}

.flight-right-cart {
    margin-top: 11px;
    height: 163px;
    border-radius: 22px 0px 0px 22px;
    /* background-color: #ffffff; */
    margin-left: 8px;
}

.flight-info {
    padding: 21px 34px 21px 34px;
}

.airline-info {
    /* min-width: 117px; */
    padding:0px 15px 0px 15px;
    height: 29.71px;
    border-radius: 10px;
    /* background-color: #000; */
    /* color: #FFFFFF; */
    border: none;
    outline: none;
    cursor: pointer;
    /* font-family: Figtree; */
    font-size: 12px;
    font-weight: 800;
    line-height: 14.4px;
    text-align: center;

}

.total-users {
    /* font-family: Figtree; */
    font-size: 12px;
    font-weight: 700;
    line-height: 14.4px;
    text-align: right;
    padding-top: 2px;
    padding-left: 4px;
    padding-right: 5px;


}

.cart-total-users {
    white-space: nowrap;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-end;
    padding-right: 2px;
}

.chevron-down {
    padding-bottom: 2px;
    padding-left: 13px;
}
</style>