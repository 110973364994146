<template>

    <div class="thank-you">
        <p class="text-center thank-you-text" :style="{ color: data.brand.whitelabel_config.fontColor }">{{ data.text.thank_you }}</p>
        <p class="text-center your-order-is-confirmed-text mb-0" :style="{ color: data.brand.whitelabel_config.fontColor }">{{ data.text.your_order_is_confirmed }}</p>
        <p class="text-center your-order-is-confirmed-text" :style="{ color: data.brand.whitelabel_config.fontColor }">{{ data.text.have_a_nice_trip }}</p>

        <div class="text-center mb-4">

            <button onclick="window.location.reload()" type="button" class="go-to-home-button mb-3"
            :style="{ background: data.brand.whitelabel_config.buttonBackgroundColor, color: data.brand.whitelabel_config.buttonTextColor }">{{ data.text.return_to_home }}</button>

        </div>
    </div>
</template>


<script>

export default {
    props: ['data'],
};
</script>

<style scoped>
.thank-you-text {
    margin-top: 50px;
    font-family: "Figtree";
    font-weight: normal;
    font-style: normal;
    font-size: 44px;
    line-height: 56px;
    letter-spacing: 0px;
    /* color: #252A31; */
    opacity: 1;
}

.your-order-is-confirmed-text {
    font-family: "Figtree";
    font-weight: normal;
    font-style: normal;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0px;
    /* color: #252A31; */
    opacity: 1;
    width: 100%
}

.go-to-home-button {
    margin-top: 40px;
    height: 40px;
    width: 100%;
    border: none;
    border-radius: 2px;
    font-family: "Figtree";
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    margin-bottom: 30px;
    width: 90%;

}

.thank-you {
    /* background: white; */
    min-height: 100vh;
    padding: 0px;
    overflow-x: hidden;
}
</style>