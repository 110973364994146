import * as moment from "moment";
export default {
  loading: true,
  data: null,
  journeys: [],
  moment: moment,
  cartItems: [],
  tatalPrice: 0,
  apiUrlStorage: process.env.VUE_APP_API_URL_STORAGE,
  step: 0,
  stripeData: null,
  stripe: null,
  stripeElements: null,
  isStripeSubmitting: false,
  errorMessage: '',
  loadingStripe: true,
  deviceType: null,
  showItemsAsIcon: true,
  selectedJourneyIndex: null,
  apiAirportUrl: process.env.VUE_APP_API_AIRPORT_URL,
  error: false



}
