
export default {
  async loadData({ commit, state }, { id }) {

    try {
      const { data } = await window.axios.get(
        process.env.VUE_APP_API_V2_URL + `ancillaries/all-in-one/${id}`
      );

      commit('SAVE_INITIAL_DATA', { data: data.data, loading: false, commit})

    } catch (error) {
      state.loading = false
      state.error = true;
    }
  },


  async openSeatMap({ commit, state }) {
    document.querySelector('button#open-seat-selector-link').click();
  },

  async loadSeat({ commit, state }) {

    window.SeatSelector = new window.Airobot.SeatSelector({
      container: `seat-selector-container`,
      button: `open-seat-selector-link`,
      token: state.data.ancillary_token,
      type: 'widget',
      showOnStart: false,
      autoChangeFlight: true,
      currencyFormatter: function (number, currency) {
        return number.toFixed(2) + currency.symbol;
      }
    }).on('seat-selected', function (seat) {
      // console.log('seat-selected', seat);

    }).on('seat-deselected', function (passenger) {
      // console.log('seat-deselected', passenger);
    }).on('seat-selection-completed', function (seats) {
      // console.log('seat-selection-completed', seats);
      commit('CLEAR_SEATS')
      commit('SEAT_ADD_PASSENGER_SERVICE', { seats, commit })


    }).on('seat-selection-removed', function (seats) {
      // console.log('seat-selection-removed', seats);
    }).on('seat-selection-hide', function () {
      // console.log('hide');
    }).on('seat-selection-show', function () {
      // console.log('show');
    });

  },




  addServiceForAllPassengers({ commit, getters }, { journeyIndex, service, serviceIndex, passengers }) {
    let passengersData = JSON.parse(JSON.stringify(passengers));
    passengersData.forEach(passenger => {
      commit('ADD_PASSENGER_SERVICE', { journeyIndex, service, passenger });
      commit('ADDED_SERVICE_PASSENGER', { getters, journeyIndex, serviceIndex, passenger });
    });
  },

  addService({ commit, getters }, { journeyIndex, service, serviceIndex, passenger }) {
    commit('ADD_PASSENGER_SERVICE', { journeyIndex, service, passenger })

    commit('ADDED_SERVICE_PASSENGER', { getters, journeyIndex, serviceIndex, passenger })
  },

  removeFromCart({ commit }, { id }) {
    commit('REMOVE_FROM_CART', { id })
  },

  nextStep({ commit }) {
    commit('next_step', { commit })
  },

  previousStep({ commit }) {
    commit('previous_step')
  },

  removePassenger({ commit }, { cartItem, cartItemIndex, addedService, addedServiceIndex, addedPassenger, addedPassengerIndex }) {
    commit('REMOVE_PASSENGER', { cartItem, cartItemIndex, addedService, addedServiceIndex, addedPassengerIndex })
    commit('REMOVE_PASSENGER_SERVICE', { cartItem, addedService, addedPassenger })
  },

  loadStripe({ commit }) {
    commit('LOAD_STRIPE')
  },

  handleSubmit({ commit }) {
    commit('HANDLE_SUBMIT')
  },

  changeShowItemType({ commit }) {
    commit('CHANGE_SHOW_ITEM_TYPE')
  },

  selectJourney({ commit }, { journeyIndex}) {
    commit('SELECT_JOURNEY', {journeyIndex})
  },

  unselectJourney({ commit }) {
    commit('UNSELECT_JOURNEY')
  },

  reloadPage({ commit }) {
    window.location.reload();
  }
  
}