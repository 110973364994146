<template>
    <footer class="whitelabel-footer" :style="{background: data.brand.whitelabel_config.footerBackgroundColor}">
        <div class="container">
            <div class="row">
                <div class="col-8">
                    <img v-if="data.brand.whitelabel_config.footer_logo !== null" :src="apiUrlStorage + data.brand.whitelabel_config.footer_logo" width="163" />
                    <div class="powered-by-airobot" :style="{ color: data.brand.whitelabel_config.footerFontColor2}">Powered by Airobot<sup>TM</sup></div>
                </div>
                <div class="col-2">
                    <div class="policy-items">
                        <div class="policy" :style="{ color: data.brand.whitelabel_config.footerFontColor}">Policy</div>
                        <div><a href="#" :style="{ color: data.brand.whitelabel_config.footerFontColor2}">Cookie Policy</a></div>
                        <div><a href="#" :style="{ color: data.brand.whitelabel_config.footerFontColor2}">Privacy Policy</a></div>
                        <div><a href="#" :style="{ color: data.brand.whitelabel_config.footerFontColor2}">Terms & Conditions</a></div>
                    </div>
                </div>
                <div class="col-2">
                    <div class="policy-items">
                        <div class="policy" :style="{ color: data.brand.whitelabel_config.footerFontColor}">Policy</div>
                        <div><a href="#" :style="{ color: data.brand.whitelabel_config.footerFontColor2}">Cookie Policy</a></div>
                        <div><a href="#" :style="{ color: data.brand.whitelabel_config.footerFontColor2}">Privacy Policy</a></div>
                        <div><a href="#" :style="{ color: data.brand.whitelabel_config.footerFontColor2}">Terms & Conditions</a></div>
                    </div>
                </div>
            </div>

            <div class="row information-text-container">
                <div class="col-8">
                    <!-- <div class="information-text" :style="{ color: data.brand.whitelabel_config.footerFontColor2}">Information lorem ipsum dolor sit amet</div> -->
                </div>
                <div class="col-2">
                </div>

                <div class="col-2">
                    <div class="all-rights-reserved" :style="{ color: data.brand.whitelabel_config.footerFontColor2}">
                        © 2024 All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>


<script>

export default {
    props: ['data', 'apiUrlStorage'],
};
</script>

<style scoped>
footer.whitelabel-footer {
    margin-top: 80px;
    min-height: 325px;
    /* background: #86CAF0; */
}

footer .container {
    min-height: 234px;
    padding-top: 45px;
}

.powered-by-airobot {
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    text-align: left;
    /* color: #FFFFFF; */
    height: 44px;
    display: flex;
    align-items: center;
}

.policy {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.12px;
    text-align: center;
    height: 19px;
    display: flex;
    align-items: center;
    /* color: #D40E14; */
    margin-bottom: 15px;
}

.policy-items a {
    /* color: #FFFFFF; */
    text-decoration: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
    text-align: left;
}

.information-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
    text-align: left;
    /* color: #FFFFFF; */
}

.all-rights-reserved {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
    /* color: #FFFFFF; */
}

.information-text-container {
    margin-top: 110px;
}
</style>