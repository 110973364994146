<template>

    <div class="container">
        <div class="lets-get-ready-for-your-trip-container">
            <div class="lets-get-ready-for-your-trip-text-continer">
                <p class="lets-get-ready-for-your-trip-text" :style="{ color: data.brand.whitelabel_config.fontColor }">
                    <!-- <img src="../../assets/images/back.png"
                        @click="unselectJourney()"> -->
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
                        @click="unselectJourney()">
                        <rect x="0.603448" y="0.603448" width="28.7931" height="28.7931" rx="11.3966"
                            :stroke="data.brand.whitelabel_config.fontColor" stroke-width="1.2069" />
                        <path
                            d="M16.5 20.75C16.2891 20.75 16.1016 20.6797 15.9609 20.5391L11.4609 16.0391C11.1562 15.7578 11.1562 15.2656 11.4609 14.9844L15.9609 10.4844C16.2422 10.1797 16.7344 10.1797 17.0156 10.4844C17.3203 10.7656 17.3203 11.2578 17.0156 11.5391L13.0547 15.5L17.0156 19.4844C17.3203 19.7656 17.3203 20.2578 17.0156 20.5391C16.875 20.6797 16.6875 20.75 16.5 20.75Z"
                            :fill="data.brand.whitelabel_config.fontColor" />
                    </svg>

                    {{ data.text.lets_get_ready_for_your_trip }}
                </p>
            </div>
        </div>

        <div class="flight-cards row">
            <div class="flight-col col-12">
                <div class="photo-flight-card card">
                    <img class="city-image"
                        :src="`${apiAirportUrl}${selectedJourney.arrival_airport_image}`">
                    <div class="card-img-overlay">
                        <div class="flight-card-container row">


                            <div class="col-lg-6  col-xl-5 p-0">
                                <div class="flight-card"
                                    :style="{ background: data.brand.whitelabel_config.cartBackgroundColor }">
                                    <div class="flight-info-container">
                                        <div class="flight-info">
                                            <div class="row m-0">
                                                <div class="col-9 ps-0">
                                                    <button class="airline-info"
                                                        :style="{ background: data.brand.whitelabel_config.fontColor, color: data.brand.whitelabel_config.cartBackgroundColor }">{{
                                                        selectedJourney.airline_name }}
                                                        {{ selectedJourney.airline }}{{ selectedJourney.flight_number
                                                        }}</button>
                                                </div>
                                                <div class="col-3 cart-total-users">
                                                    <template v-for="(passenger, index) in data.payload.passengers"
                                                        :key="index">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                            viewBox="9.620000000000001 0 13.32 18.51851851851852"
                                                            width="15.32" height="18.51851851851852">

                                                            <path :fill="data.brand.whitelabel_config.fontColor"
                                                                d="M16.1836 6.5C14.5195 6.5 13.1836 5.16406 13.1836 3.5C13.1836 1.85938 14.5195 0.5 16.1836 0.5C17.8242 0.5 19.1836 1.85938 19.1836 3.5C19.1836 5.16406 17.8242 6.5 16.1836 6.5ZM17.3555 7.625C19.6055 7.625 21.4336 9.45312 21.4336 11.7031C21.4336 12.1484 21.0586 12.5 20.6133 12.5H11.7305C11.2852 12.5 10.9336 12.1484 10.9336 11.7031C10.9336 9.45312 12.7383 7.625 14.9883 7.625H17.3555ZM31.1836 6.5C29.5195 6.5 28.1836 5.16406 28.1836 3.5C28.1836 1.85938 29.5195 0.5 31.1836 0.5C32.8242 0.5 34.1836 1.85938 34.1836 3.5C34.1836 5.16406 32.8242 6.5 31.1836 6.5ZM32.3555 7.625C34.6055 7.625 36.4336 9.45312 36.4336 11.7031C36.4336 12.1484 36.0586 12.5 35.6133 12.5H26.7305C26.2852 12.5 25.9336 12.1484 25.9336 11.7031C25.9336 9.45312 27.7383 7.625 29.9883 7.625H32.3555Z" />
                                                        </svg>
                                                    </template>

                                                    <span class="total-users"
                                                        :style="{ color: data.brand.whitelabel_config.fontColor }"> {{
                                                            data.payload.passengers.length
                                                        }}</span>

                                                </div>
                                                <div class="col-12 p-0"><span class="confirmation-code"
                                                        :style="{ color: data.brand.whitelabel_config.fontColor2 }">
                                                        {{ data.text.confirmation_code }} {{ selectedJourney.pnr }}</span>
                                                </div>
                                            </div>
                                            <div class="row flight-city-info"
                                                :style="{ color: data.brand.whitelabel_config.fontColor2 }">
                                                <div class="col-6 ps-0"><span class="flight-city">{{
                                                    selectedJourney.departure_airport_name }}</span>
                                                </div>
                                                <div class="col-6 text-end pe-0"><span class="flight-city">{{
                                                    selectedJourney.arrival_airport_name }}</span>
                                                </div>
                                            </div>
                                            <div class="row airport-iata-info"
                                                :style="{ color: data.brand.whitelabel_config.fontColor }">
                                                <div class="col-4 ps-0"><span class="airport-iata">{{
                                                    selectedJourney.departure_airport }}</span>
                                                </div>
                                                <div class="col-4 d-flex align-items-center justify-content-center"><svg
                                                        width="18" height="16" viewBox="0 0 27 27" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M21.9725 10.5C23.4999 10.5 26.1952 11.8027 26.1952 13.375C26.1952 14.9922 23.4999 16.25 21.9725 16.25H16.7167L12.2245 24.1562C11.955 24.6055 11.4608 24.875 10.9667 24.875H8.45105C7.95691 24.875 7.59753 24.4258 7.7323 23.9766L9.93347 16.25H5.35144L3.37488 18.8555C3.24011 19.0352 3.06042 19.125 2.83582 19.125H0.949097C0.589722 19.125 0.32019 18.8555 0.32019 18.4961C0.32019 18.4512 0.32019 18.4062 0.32019 18.3613L1.75769 13.375L0.32019 8.43359C0.32019 8.38867 0.32019 8.34375 0.32019 8.25391C0.32019 7.93945 0.589722 7.625 0.949097 7.625H2.83582C3.06042 7.625 3.24011 7.75977 3.37488 7.93945L5.35144 10.5H9.93347L7.7323 2.81836C7.59753 2.36914 7.95691 1.875 8.45105 1.875H10.9667C11.4608 1.875 11.955 2.18945 12.2245 2.63867L16.7167 10.5H21.9725Z"
                                                            :fill="data.brand.whitelabel_config.fontColor"></path>
                                                    </svg></div>
                                                <div class="col-4 text-end pe-0"><span class="airport-iata">{{
                                                    selectedJourney.arrival_airport }}</span>
                                                </div>
                                            </div>
                                            <div class="row departure-date-info"
                                                :style="{ color: data.brand.whitelabel_config.fontColor }">
                                                <div class="col-5 ps-0"><span class="date">{{ moment(
                                                    selectedJourney.departure_date_utc).format("MMM D, h:mm A")
                                                        }}</span></div>
                                                <div class="col-2 d-flex align-items-center justify-content-center p-0">
                                                    <span class="date">{{
                                                        getHoursMinutesDifference(selectedJourney.departure_date_utc,
                                                            selectedJourney.arrival_date_utc) }}</span>
                                                </div>
                                                <div class="col-5 text-end pe-0"><span class="date arrival-date"> {{
                                                    moment(
                                                        selectedJourney.arrival_date_utc).format("MMM D, h:mm A")
                                                        }}</span>
                                                </div>
                                            </div>
                                            <div class="border-pass"></div>
                                            <div class="row passenger-info"
                                                v-for="(passenger, index) in selectedJourney.passengers" :key="index">
                                                <div class="col-12 passengers-names"
                                                    :style="{ color: data.brand.whitelabel_config.fontColor2 }"> {{
                                                    data.text.passenger }} {{
                                                        index + 1
                                                    }}
                                                </div>
                                                <div class="row passenger-name-container">
                                                    <div class="col-12 passenger-name"
                                                        :style="{ color: data.brand.whitelabel_config.fontColor }"> {{
                                                        passenger.name
                                                        }} {{
                                                            passenger.last_name }}
                                                    </div>
                                                    <!-- <div class="col-5 passenger-no-service-added"
                                                    v-if="passenger.services.length == 0">
                                                    {{ data.text.no_service_added }}
                                                </div> -->
                                                    <div class="service-added"
                                                        v-for="(passengerService, passengerServiceIndex) in passenger.services"
                                                        :key="passengerServiceIndex"
                                                        :style="{ color: data.brand.whitelabel_config.fontColor2 }">
                                                        <span class="service-icon">
                                                            <template v-if="passengerService.type == 'check-in'">
                                                                <svg width="14" height="10" viewBox="0 0 14 10"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M0 1.53125C0 0.710938 0.65625 0.03125 1.5 0.03125H12C12.8203 0.03125 13.5 0.710938 13.5 1.53125V3.40625C12.8672 3.40625 12.375 3.92188 12.375 4.53125C12.375 5.16406 12.8672 5.65625 13.5 5.65625V7.53125C13.5 8.375 12.8203 9.03125 12 9.03125H1.5C0.65625 9.03125 0 8.375 0 7.53125V5.65625C0.609375 5.65625 1.125 5.16406 1.125 4.53125C1.125 3.92188 0.609375 3.40625 0 3.40625V1.53125Z"
                                                                        :fill="data.brand.whitelabel_config.fontColor2" />
                                                                </svg>
                                                            </template>
                                                            <template
                                                                v-else-if="passengerService.type == 'seat-selection'">
                                                                <svg width="15" height="11" viewBox="0 0 15 11"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M13.875 4.78125C14.4844 4.78125 15 5.29688 15 5.90625V10.4062C15 10.6172 14.8125 10.7812 14.625 10.7812H13.125C12.9141 10.7812 12.75 10.6172 12.75 10.4062V10.0312H2.25L2.22656 10.4062C2.22656 10.6172 2.0625 10.7812 1.875 10.7812H0.375C0.164062 10.7812 0 10.6172 0 10.4062V5.90625C0 5.29688 0.492188 4.78125 1.125 4.78125C1.73438 4.78125 2.25 5.29688 2.25 5.90625V7.78125H12.75V5.90625C12.75 5.29688 13.2422 4.78125 13.875 4.78125ZM3 5.90625C3 5.01562 2.34375 4.26562 1.5 4.07812L1.47656 3.28125C1.47656 1.64062 2.8125 0.28125 4.47656 0.28125H10.5C12.1406 0.28125 13.5 1.64062 13.5 3.28125V4.07812C12.6328 4.26562 12 5.01562 12 5.90625V7.03125H3V5.90625Z"
                                                                        :fill="data.brand.whitelabel_config.fontColor2" />
                                                                </svg>
                                                            </template>
                                                            <template v-else-if="passengerService.type == 'fast-track'">
                                                                <svg width="10" height="13" viewBox="0 0 10 13"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M9.42969 4.58594C9.52344 4.77344 9.5 5.00781 9.38281 5.17188L4.50781 12.2969C4.41406 12.4609 4.22656 12.5312 4.0625 12.5312C3.96875 12.5312 3.89844 12.5312 3.82812 12.4844C3.57031 12.3672 3.45312 12.1094 3.5 11.8516L4.48438 7.28125H1.0625C0.898438 7.28125 0.734375 7.21094 0.617188 7.09375C0.523438 6.97656 0.476562 6.8125 0.5 6.64844L1.25 1.02344C1.27344 0.742188 1.50781 0.53125 1.8125 0.53125H5.9375C6.10156 0.53125 6.28906 0.625 6.38281 0.789062C6.5 0.953125 6.52344 1.14062 6.45312 1.30469L5.25781 4.28125H8.9375C9.125 4.28125 9.33594 4.39844 9.42969 4.58594Z"
                                                                        :fill="data.brand.whitelabel_config.fontColor2" />
                                                                </svg>
                                                            </template>
                                                        </span><span class="service-added-text">{{
                                                            data.serviceTexts[passengerService.type].name }}</span>
                                                        <span class="service-added-price">{{formatCurrency(passengerService.price)
                                                            }} 
                                                            {{
                                                                passengerService.paid ? data.text.paid : '' }} </span>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="scroll-gradient" :style="{ background: data.brand.whitelabel_config.cartBackgroundColor }"></div> -->
                                    </div>
                                </div>

                            </div>

                            <!-- <template>  -->

                            <div class="col-lg-6 col-xl-7 row services-container">
                                <div class="col-12 p-0">
                                    <span class="select-services"
                                        :style="{ color: data.brand.whitelabel_config.fontColor }">{{
                                            data.text.select_services }}</span>
                                </div>
                                <template v-for="(service, serviceIndex) in selectedJourney.services"
                                    :key="serviceIndex">
                                    <template v-if="!addedAllServicePassenger(service.passengers)">
                                        <template v-if="service.type == 'seat-selection'">
                                            <template v-if="data.displaySeatMap">
                                                <div class="col-lg-6  col-xl-4 p-0">
                                                    <div class="service-card"
                                                        :style="{ background: data.brand.whitelabel_config.cartBackgroundColor }">
                                                        <div class="service-info">
                                                            <div class="row m-0">
                                                                <div class="col-4">
                                                                    <svg width="23" height="17" viewBox="0 0 23 17"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M20.8125 7.125C21.7266 7.125 22.5 7.89844 22.5 8.8125V15.5625C22.5 15.8789 22.2188 16.125 21.9375 16.125H19.6875C19.3711 16.125 19.125 15.8789 19.125 15.5625V15H3.375L3.33984 15.5625C3.33984 15.8789 3.09375 16.125 2.8125 16.125H0.5625C0.246094 16.125 0 15.8789 0 15.5625V8.8125C0 7.89844 0.738281 7.125 1.6875 7.125C2.60156 7.125 3.375 7.89844 3.375 8.8125V11.625H19.125V8.8125C19.125 7.89844 19.8633 7.125 20.8125 7.125ZM4.5 8.8125C4.5 7.47656 3.51562 6.35156 2.25 6.07031L2.21484 4.875C2.21484 2.41406 4.21875 0.375 6.71484 0.375H15.75C18.2109 0.375 20.25 2.41406 20.25 4.875V6.07031C18.9492 6.35156 18 7.47656 18 8.8125V10.5H4.5V8.8125Z"
                                                                            :fill="data.brand.whitelabel_config.fontColor" />
                                                                    </svg>
                                                                    <!-- <img :src="require(`../../assets/images/desktop/services/black/${service.type}.svg`)"
                                                                        alt="service"> -->
                                                                </div>
                                                                <div class="col-8">
                                                                    <button class="service-price"
                                                                        :style="{ color: data.brand.whitelabel_config.cartBackgroundColor, background: data.brand.whitelabel_config.fontColor }"> 
                                                                        {{formatCurrency(5)
                                                                        }}  ></button>
                                                                </div>
                                                                <span class="service-name"
                                                                    :style="{ color: data.brand.whitelabel_config.fontColor }">{{
                                                                        data.serviceTexts[service.type].name }}</span>
                                                                <span class="service-description"
                                                                    :style="{ color: data.brand.whitelabel_config.fontColor }">
                                                                    {{
                                                                        data.serviceTexts[service.type].description
                                                                    }}</span>
                                                                <span class="add-service-container">

                                                                    <button class="add-service" @click="openSeatMap()"
                                                                        :style="{ '--backgroundColor': data.brand.whitelabel_config.buttonBackgroundColor, '--labelColor': data.brand.whitelabel_config.cartBackgroundColor }">{{
                                                                            data.text.add }}</button>

                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <div class="col-lg-6  col-xl-4 p-0">
                                                <div class="service-card"
                                                    :style="{ background: data.brand.whitelabel_config.cartBackgroundColor }">
                                                    <div class="service-info">
                                                        <div class="row m-0">
                                                            <div class="col-4">

                                                                <svg width="21" height="14" viewBox="0 0 21 14"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg"
                                                                    v-if="service.type == 'check-in'">
                                                                    <path
                                                                        d="M0 2.75C0 1.51953 0.984375 0.5 2.25 0.5H18C19.2305 0.5 20.25 1.51953 20.25 2.75V5.5625C19.3008 5.5625 18.5625 6.33594 18.5625 7.25C18.5625 8.19922 19.3008 8.9375 20.25 8.9375V11.75C20.25 13.0156 19.2305 14 18 14H2.25C0.984375 14 0 13.0156 0 11.75V8.9375C0.914062 8.9375 1.6875 8.19922 1.6875 7.25C1.6875 6.33594 0.914062 5.5625 0 5.5625V2.75Z"
                                                                        :fill="data.brand.whitelabel_config.fontColor" />
                                                                </svg>

                                                                <svg width="15" height="19" viewBox="0 0 15 19"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg"
                                                                    v-else-if="service.type == 'fast-track'">
                                                                    <path
                                                                        d="M14.3945 6.33203C14.5352 6.61328 14.5 6.96484 14.3242 7.21094L7.01172 17.8984C6.87109 18.1445 6.58984 18.25 6.34375 18.25C6.20312 18.25 6.09766 18.25 5.99219 18.1797C5.60547 18.0039 5.42969 17.6172 5.5 17.2305L6.97656 10.375H1.84375C1.59766 10.375 1.35156 10.2695 1.17578 10.0938C1.03516 9.91797 0.964844 9.67188 1 9.42578L2.125 0.988281C2.16016 0.566406 2.51172 0.25 2.96875 0.25H9.15625C9.40234 0.25 9.68359 0.390625 9.82422 0.636719C10 0.882812 10.0352 1.16406 9.92969 1.41016L8.13672 5.875H13.6562C13.9375 5.875 14.2539 6.05078 14.3945 6.33203Z"
                                                                        :fill="data.brand.whitelabel_config.fontColor" />
                                                                </svg>



                                                                <!-- <img :src="require(`../../assets/images/desktop/services/black/${service.type}.svg`)"
                                                                    alt="service"> -->
                                                            </div>
                                                            <div class="col-8">
                                                                <button class="service-price"
                                                                    :style="{ color: data.brand.whitelabel_config.cartBackgroundColor, background: data.brand.whitelabel_config.fontColor }">
                                                                    {{formatCurrency(service.price)}} </button>
                                                            </div>
                                                            <span class="service-name"
                                                                :style="{ color: data.brand.whitelabel_config.fontColor }">{{
                                                                    data.serviceTexts[service.type].name }}</span>
                                                            <span class="service-description"
                                                                :style="{ color: data.brand.whitelabel_config.fontColor }">
                                                                {{
                                                                    data.serviceTexts[service.type].description }}</span>
                                                            <span class="add-service-container">

                                                                <button class="add-service" data-bs-toggle="modal"
                                                                    :data-bs-target="`#addPassengerModal${selectedJourneyIndex}${serviceIndex}`"
                                                                    :style="{ '--backgroundColor': data.brand.whitelabel_config.buttonBackgroundColor, '--labelColor': data.brand.whitelabel_config.cartBackgroundColor }">{{
                                                                        data.text.add }}</button>

                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <div class="col-lg-6  col-xl-4 p-0" v-if="service.type == 'seat-selection'">
                                            <div class="service-card"
                                                :style="{ background: data.brand.whitelabel_config.cartBackgroundColor }">
                                                <div class="service-info">
                                                    <div class="row m-0">
                                                        <div class="col-4">
                                                            <svg width="23" height="17" viewBox="0 0 23 17" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M20.8125 7.125C21.7266 7.125 22.5 7.89844 22.5 8.8125V15.5625C22.5 15.8789 22.2188 16.125 21.9375 16.125H19.6875C19.3711 16.125 19.125 15.8789 19.125 15.5625V15H3.375L3.33984 15.5625C3.33984 15.8789 3.09375 16.125 2.8125 16.125H0.5625C0.246094 16.125 0 15.8789 0 15.5625V8.8125C0 7.89844 0.738281 7.125 1.6875 7.125C2.60156 7.125 3.375 7.89844 3.375 8.8125V11.625H19.125V8.8125C19.125 7.89844 19.8633 7.125 20.8125 7.125ZM4.5 8.8125C4.5 7.47656 3.51562 6.35156 2.25 6.07031L2.21484 4.875C2.21484 2.41406 4.21875 0.375 6.71484 0.375H15.75C18.2109 0.375 20.25 2.41406 20.25 4.875V6.07031C18.9492 6.35156 18 7.47656 18 8.8125V10.5H4.5V8.8125Z"
                                                                    :fill="data.brand.whitelabel_config.fontColor" />
                                                            </svg>
                                                        </div>
                                                        <div class="col-8">
                                                            <button class="service-price"
                                                                :style="{ color: data.brand.whitelabel_config.cartBackgroundColor, background: data.brand.whitelabel_config.fontColor }"> 
                                                                {{formatCurrency(5)
                                                                }} ></button>
                                                        </div>
                                                        <span class="service-name"
                                                            :style="{ color: data.brand.whitelabel_config.fontColor }">{{
                                                                data.serviceTexts[service.type].name }}</span>
                                                        <span class="service-description"
                                                            :style="{ color: data.brand.whitelabel_config.fontColor }">
                                                            {{
                                                                data.serviceTexts[service.type].description
                                                            }}</span>
                                                        <span class="add-service-container">

                                                            <button class="service-added-button"
                                                                :style="{ '--backgroundColor': data.brand.whitelabel_config.buttonBackgroundColor, '--labelColor': data.brand.whitelabel_config.cartBackgroundColor }">{{
                                                                    data.text.added }}</button>

                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6  col-xl-4 p-0" v-else>
                                            <div class="service-card"
                                                :style="{ background: data.brand.whitelabel_config.cartBackgroundColor }">
                                                <div class="service-info">
                                                    <div class="row m-0">
                                                        <div class="col-4">
                                                            <svg width="21" height="14" viewBox="0 0 21 14" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                v-if="service.type == 'check-in'">
                                                                <path
                                                                    d="M0 2.75C0 1.51953 0.984375 0.5 2.25 0.5H18C19.2305 0.5 20.25 1.51953 20.25 2.75V5.5625C19.3008 5.5625 18.5625 6.33594 18.5625 7.25C18.5625 8.19922 19.3008 8.9375 20.25 8.9375V11.75C20.25 13.0156 19.2305 14 18 14H2.25C0.984375 14 0 13.0156 0 11.75V8.9375C0.914062 8.9375 1.6875 8.19922 1.6875 7.25C1.6875 6.33594 0.914062 5.5625 0 5.5625V2.75Z"
                                                                    :fill="data.brand.whitelabel_config.fontColor" />
                                                            </svg>

                                                            <svg width="15" height="19" viewBox="0 0 15 19" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                v-else-if="service.type == 'fast-track'">
                                                                <path
                                                                    d="M14.3945 6.33203C14.5352 6.61328 14.5 6.96484 14.3242 7.21094L7.01172 17.8984C6.87109 18.1445 6.58984 18.25 6.34375 18.25C6.20312 18.25 6.09766 18.25 5.99219 18.1797C5.60547 18.0039 5.42969 17.6172 5.5 17.2305L6.97656 10.375H1.84375C1.59766 10.375 1.35156 10.2695 1.17578 10.0938C1.03516 9.91797 0.964844 9.67188 1 9.42578L2.125 0.988281C2.16016 0.566406 2.51172 0.25 2.96875 0.25H9.15625C9.40234 0.25 9.68359 0.390625 9.82422 0.636719C10 0.882812 10.0352 1.16406 9.92969 1.41016L8.13672 5.875H13.6562C13.9375 5.875 14.2539 6.05078 14.3945 6.33203Z"
                                                                    :fill="data.brand.whitelabel_config.fontColor" />
                                                            </svg>
                                                        </div>
                                                        <div class="col-8">
                                                            <button class="service-price"
                                                                :style="{ color: data.brand.whitelabel_config.cartBackgroundColor, background: data.brand.whitelabel_config.fontColor }">
                                                                 {{ formatCurrency(service.price) }}</button>
                                                        </div>
                                                        <span class="service-name"
                                                            :style="{ color: data.brand.whitelabel_config.fontColor }">{{
                                                                data.serviceTexts[service.type].name }}</span>
                                                        <span class="service-description"
                                                            :style="{ color: data.brand.whitelabel_config.fontColor }">
                                                            {{
                                                                data.serviceTexts[service.type].description
                                                            }}</span>
                                                        <span class="add-service-container">

                                                            <button class="service-added-button"
                                                                :style="{ '--backgroundColor': data.brand.whitelabel_config.buttonBackgroundColor, '--labelColor': data.brand.whitelabel_config.cartBackgroundColor }">{{
                                                                    data.text.added }}</button>

                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <AddPassengerModalViewD :data="data" :journeyIndex="selectedJourneyIndex"
                                        :service="service" :serviceIndex="serviceIndex" />

                                </template>

                            </div>
                        </div>

                        <div class="checkout-container">
                            <button class="checkout" @click="nextStep()" :disabled="disableCheckout"
                                :style="{ '--backgroundColor': data.brand.whitelabel_config.buttonBackgroundColor, '--labelColor': data.brand.whitelabel_config.buttonTextColor }">{{
                                    data.text.checkout }}

                                <span class="arrow-right">
                                    <svg width="15" height="13" viewBox="0 0 15 13" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.537 7.71875L9.53704 12.7188C9.34954 12.9062 9.09954 13 8.84954 13C8.56829 13 8.31829 12.9062 8.13079 12.7188C7.72454 12.3438 7.72454 11.6875 8.13079 11.3125L11.412 8H1.84954C1.28704 8 0.849545 7.5625 0.849545 7C0.849545 6.46875 1.28704 6 1.84954 6H11.412L8.13079 2.71875C7.72454 2.34375 7.72454 1.6875 8.13079 1.3125C8.50579 0.90625 9.16204 0.90625 9.53704 1.3125L14.537 6.3125C14.9433 6.6875 14.9433 7.34375 14.537 7.71875Z"
                                            :fill="data.brand.whitelabel_config.buttonTextColor" />
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="or-choose-another-trip-container" v-if="journeys.length > 1">
            <div class="or-choose-another-trip-text-container">
                <p class="or-choose-another-trip-text" :style="{ color: data.brand.whitelabel_config.fontColor }">{{
                    data.text.or_choose_another_trip }}</p>
            </div>
        </div>

        <AncillaryFlightsViewD :data="data" :journeys="journeys" :moment="moment"
            :selectedJourneyIndex="selectedJourneyIndex" :apiAirportUrl="apiAirportUrl">
        </AncillaryFlightsViewD>

    </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import AncillaryFlightsViewD from "./AncillaryFlightsView.vue";
import AddPassengerModalViewD from "./AddPassengerModalView.vue";
export default {
    components: {
        AncillaryFlightsViewD,
        AddPassengerModalViewD,
    },

    props: ['data', 'journeys', 'moment', 'selectedJourney', 'selectedJourneyIndex', 'disableCheckout', 'nextStep', 'apiAirportUrl'],
    computed: {
        ...mapGetters(['getHoursMinutesDifference', 'addedAllServicePassenger', 'formatCurrency']),
    },
    methods: {
        ...mapActions(['unselectJourney', 'openSeatMap']),
    },

}
</script>

<style scoped>
.checkout:disabled {
    cursor: not-allowed !important;
    opacity: 0.8;
}

.scroll-gradient {
    position: absolute;
    bottom: 40px;
    height: 31px;
    background: rgba(255, 255, 255, 0.5);
    width: 310px;
}

.flight-info {
    max-height: 420px;
    overflow-y: scroll;
    padding-right: 22px;
    box-sizing: content-box;
    position: relative;
}

.flight-info::-webkit-scrollbar {
    width: 5px;
}

.flight-info::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 6px;
}

.flight-info::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
}

.flight-info-container {
    padding: 25px 3px 25px 25px;
}

.passenger-no-service-added {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.39px;
    text-align: left;
    /* color: #868686; */
    /* padding: 0px; */

}

.service-added-button {
    width: 129px;
    height: 35px;
    gap: 0px;
    border-radius: 12.07px;
    border: 1.21px;
    opacity: 0px;
    border: 1.21px solid var(--backgroundColor);
    background: var(--labelColor);

    font-size: 14px;
    font-weight: 700;
    line-height: 19.12px;
    text-align: center;
    color: var(--backgroundColor);

    opacity: 0.5;
    cursor: not-allowed;
}

.services-container {
    height: 0px;
}

.or-choose-another-trip-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 21.86px;
    text-align: center;
    /* color: #000000; */
    margin: 0px;
}

.or-choose-another-trip-text-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.or-choose-another-trip-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 63px;
}

.arrow-right {
    padding: 0px 0px 2px 30px;
}

.checkout {
    position: absolute;
    right: 30px;
    bottom: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 159px;
    height: 50px;
    border-radius: 20px;
    border: 1px solid var(--backgroundColor);

    font-size: 14px;
    font-weight: 600;
    line-height: 19.12px;
    background: var(--backgroundColor);
    color: var(--labelColor);
    text-transform: capitalize;
}



.add-service-container {
    margin: 7px 0px 0px 0px;
}

.add-service:hover {
    background: var(--backgroundColor);
    color: var(--labelColor);
    border: var(--labelColor);
}

.add-service {
    width: 129px;
    height: 35px;
    gap: 0px;
    border-radius: 12.07px;
    border: 1.21px;
    /* opacity: 0px; */
    border: 1.21px solid var(--backgroundColor);
    background: var(--labelColor);

    font-size: 14px;
    font-weight: 700;
    line-height: 19.12px;
    text-align: center;
    color: var(--backgroundColor);


}

.service-description {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
    /* color: #000000; */
    margin: 5px 0px 0px 0px;
}

.service-name {
    font-size: 14px;
    font-weight: 800;
    line-height: 19.12px;
    /* color: #000000; */
    margin-top: 23px;
}

.service-price {
    font-size: 15px;
    font-weight: 800;
    line-height: 20.49px;
    text-align: center;
    /* color: #FFFFFF; */
    padding: 0px 5px 0px 5px;

    height: 29.71px;
    border-radius: 10px;
    /* background: #000; */
    float: right;
    border: none;
    outline: none;
    cursor: default;
}

.service-info {
    padding: 18px 5px 5px 5px;
}

.service-price-container {
    /* width: 29.87px; */
    height: 29.71px;
    border-radius: 10px;
    /* background: #000; */
    float: right;
}

.service-card {
    width: 159px;
    height: 184px;
    gap: 0px;
    border-radius: 15.54px;
    /* opacity: 0px; */
    /* background: #FFFFFF; */
    margin-top: 20px;
}

.select-services {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.12px;
    /* color: #FFFFFF; */
}

.service-added {
    display: flex;
    align-items: center;
    padding: 0px;
    height: 27px;
    border-bottom: 0.5px solid #999999
}

.service-icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.service-added-price {
    margin-left: auto;
    font-size: 12px;
    font-weight: 700;
    line-height: 16.39px;
    text-align: right;
    /* color: #868686; */


}

.service-added-text {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.39px;
    text-align: left;
    /* color: #868686; */

}


.passenger-name {

    font-size: 14px;
    font-weight: 800;
    line-height: 19.12px;
    text-align: left;
    /* color: #000000; */
    padding: 0px;
    margin-bottom: 15px;

}


.passenger-name-container {
    margin: 10px 0px 10px 0px;
    padding: 0px;
}

.passengers-names {
    margin: 15px 0px 0px 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
    padding: 0px;
    /* color: #868686; */

}

.border-pass {
    margin: 15px 0px 0px 0px;
    border-bottom: 0.5px solid #999999;
}

.passenger-info {
    margin: 0px;
}

.departure-date-info span.arrival-date {
    justify-content: right;
}


.departure-date-info span.date {
    display: flex;
    align-items: center;
}

.departure-date-info .date {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.39px;
    /* color: #000000; */
}

.departure-date-info {
    margin: 0px;
}

.airport-iata {
    font-size: 35px;
    font-weight: 600;
    line-height: 47.81px;
    /* color: #000000; */
}

.airport-iata-info {
    margin: 0px;
}

.flight-city {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
    /* color: #868686; */
}

.confirmation-code {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
    text-transform: capitalize;
}

.flight-city-info {
    margin: 23px 0px 0px 0px;
}


.total-users {
    font-size: 12px;
    font-weight: 700;
    line-height: 19.12px;
    text-align: left;
    /* color: black; */

    margin-bottom: 5px;

}

.cart-total-users {
    white-space: nowrap;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-end;
    padding: 0px;
    margin-top: 7px;
}



.airline-info {
    /* min-width: 146.51px; */
    padding: 0px 15px 0px 15px;
    height: 32.99px;
    border-radius: 14px;
    /* background-color: #000;
    color: #FFFFFF; */
    border: none;
    outline: none;
    cursor: default;
    font-size: 14px;
    font-weight: 800;
    line-height: 19.12px;
    text-align: center;
}


.flight-card {
    min-height: 472px;
    max-height: 472px;
    max-width: 352px;
    border-radius: 30px;
    /* background-color: #ffffff; */
}

.flight-card-container {
    margin: 15px;
}


.photo-flight-card {
    height: 539px;
    /* background-color: #ffffff; */
    overflow: hidden;
    border-radius: 30px;
    object-fit: cover;
    /* gap: 55px;
opacity: 0px; */

}

.flight-cards {
    margin-top: 65px;
}

.city-image {
    height: 539px;
    object-fit: cover;
    filter: brightness(0.8);


}


.lets-get-ready-for-your-trip-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 63px;
}

.lets-get-ready-for-your-trip-text-continer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.lets-get-ready-for-your-trip-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 21.86px;
    text-align: center;
    /* color: #000000; */
    margin-bottom: 0px;
}

.lets-get-ready-for-your-trip-text svg {
    width: 30px;
    height: 30px;
    margin-right: 25px;
    cursor: pointer;
}
</style>