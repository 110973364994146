<template>


    <div class="row">
        <div class="col-1">
        </div>
        <div class="col-10">
            <div class="svg-knob-elevation-and-text" @click="previousStep()">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.603448" y="0.603448" width="23.7931" height="23.7931" rx="9.39655"
                        :stroke="data.brand.whitelabel_config.fontColor" stroke-width="1.2069" />
                    <path
                        d="M14 17.75C13.7891 17.75 13.6016 17.6797 13.4609 17.5391L8.96094 13.0391C8.65625 12.7578 8.65625 12.2656 8.96094 11.9844L13.4609 7.48438C13.7422 7.17969 14.2344 7.17969 14.5156 7.48438C14.8203 7.76562 14.8203 8.25781 14.5156 8.53906L10.5547 12.5L14.5156 16.4844C14.8203 16.7656 14.8203 17.2578 14.5156 17.5391C14.375 17.6797 14.1875 17.75 14 17.75Z"
                        :fill="data.brand.whitelabel_config.fontColor" />
                </svg>
                <p class="your-cart-text mb-0" :style="{ color: data.brand.whitelabel_config.fontColor }">{{
                    data.text.your_cart }}</p>
            </div>
        </div>
    </div>
    <template v-for="(cartItem, cartItemIndex) in cartItems" :key="cartItemIndex">
        <div class="flight-cart-container row" v-if="cartItem.addedServices.length > 0">
            <div class="col-1 p-0">
                <div class="flight-left-cart" data-bs-target="#flightCarousel" data-bs-slide="prev">
                </div>
            </div>
            <div class="col-10 p-0">
                <div class="flight-cart" :style="{ background: data.brand.whitelabel_config.cartBackgroundColor }">
                    <div class="flight-info">
                        <div class="row">
                            <div class="col-7 ps-0">
                                <button class="airline-info"
                                    :style="{ background: data.brand.whitelabel_config.fontColor, color: data.brand.whitelabel_config.cartBackgroundColor }">{{
                                    cartItem.airline_name }}
                                    {{ cartItem.airline }}{{ cartItem.flight_number }}</button>
                            </div>
                            <div class="col-5 cart-airport-flight" data-bs-toggle="collapse"
                                :href="`#passengerCollapse${cartItemIndex}`" aria-expanded="true"
                                :style="{ color: data.brand.whitelabel_config.fontColor }">

                                <span> {{ cartItem.departure_airport }}

                                    <svg class="airline-svg" width="18" height="19" viewBox="0 0 18 19" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.3428 7.71433C15.3104 7.71433 17.018 8.53966 17.018 9.53575C17.018 10.5603 15.3104 11.3572 14.3428 11.3572H11.013L8.16704 16.3661C7.99628 16.6507 7.68322 16.8214 7.37017 16.8214H5.77643C5.46337 16.8214 5.23569 16.5368 5.32107 16.2522L6.7156 11.3572H3.81271L2.56049 13.0078C2.47511 13.1217 2.36127 13.1786 2.21897 13.1786H1.02367C0.79599 13.1786 0.625232 13.0078 0.625232 12.7801C0.625232 12.7517 0.625232 12.7232 0.625232 12.6948L1.53594 9.53575L0.625232 6.40519C0.625232 6.37673 0.625232 6.34827 0.625232 6.29135C0.625232 6.09213 0.79599 5.89291 1.02367 5.89291H2.21897C2.36127 5.89291 2.47511 5.97829 2.56049 6.09213L3.81271 7.71433H6.7156L5.32107 2.84773C5.23569 2.56314 5.46337 2.25008 5.77643 2.25008H7.37017C7.68322 2.25008 7.99628 2.4493 8.16704 2.73389L11.013 7.71433H14.3428Z"
                                            :fill="data.brand.whitelabel_config.fontColor" />
                                    </svg>

                                    {{ cartItem.arrival_airport }}</span>
                                <div class="chevron-down">
                                    <svg width="11" height="7" viewBox="0 0 11 7" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.1017 6.45337L0.531385 1.90649C0.320448 1.67212 0.320448 1.32056 0.531385 1.10962L1.07045 0.570557C1.28139 0.359619 1.63295 0.359619 1.86732 0.570557L5.50014 4.17993L9.10951 0.570557C9.34389 0.359619 9.69545 0.359619 9.90639 0.570557L10.4454 1.10962C10.6564 1.32056 10.6564 1.67212 10.4454 1.90649L5.87514 6.45337C5.6642 6.66431 5.31264 6.66431 5.1017 6.45337Z"
                                            :fill="data.brand.whitelabel_config.fontColor" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="collapse row show p-o" :id="`passengerCollapse${cartItemIndex}`">
                            <template v-for="(addedService, addedServiceIndex) in cartItem.addedServices"
                                :key="addedServiceIndex">

                                <div class="col-3  p-0 flight-cart-info" data-bs-toggle="modal"
                                    :data-bs-target="`#removePassengerModal${cartItemIndex}${addedServiceIndex}`"
                                    v-if="data.brand.whitelabel_config.removePassengers == true">

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                        viewBox="0 0 8.325000000000001 18.51851851851852" style="max-height: 500px"
                                        width="8.325000000000001" height="18.51851851851852">
                                        <path :fill="data.brand.whitelabel_config.fontColor2"
                                            d="M0.12 11L2.58 7.568L4.296 5H6.168L3.78 8.348L2.016 11H0.12ZM4.428 11L2.688 8.348L0.276 5H2.148L3.864 7.568L6.312 11H4.428Z" />
                                        <path fill="black"
                                            d="M16.1836 6.5C14.5195 6.5 13.1836 5.16406 13.1836 3.5C13.1836 1.85938 14.5195 0.5 16.1836 0.5C17.8242 0.5 19.1836 1.85938 19.1836 3.5C19.1836 5.16406 17.8242 6.5 16.1836 6.5ZM17.3555 7.625C19.6055 7.625 21.4336 9.45312 21.4336 11.7031C21.4336 12.1484 21.0586 12.5 20.6133 12.5H11.7305C11.2852 12.5 10.9336 12.1484 10.9336 11.7031C10.9336 9.45312 12.7383 7.625 14.9883 7.625H17.3555ZM31.1836 6.5C29.5195 6.5 28.1836 5.16406 28.1836 3.5C28.1836 1.85938 29.5195 0.5 31.1836 0.5C32.8242 0.5 34.1836 1.85938 34.1836 3.5C34.1836 5.16406 32.8242 6.5 31.1836 6.5ZM32.3555 7.625C34.6055 7.625 36.4336 9.45312 36.4336 11.7031C36.4336 12.1484 36.0586 12.5 35.6133 12.5H26.7305C26.2852 12.5 25.9336 12.1484 25.9336 11.7031C25.9336 9.45312 27.7383 7.625 29.9883 7.625H32.3555Z" />
                                    </svg>

                                    <template
                                        v-for="(addedPassenger, addedPassengerIndex) in addedService.addedPassengers"
                                        :key="addedPassengerIndex">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                            v-if="!addedPassenger.removed"
                                            viewBox="9.620000000000001 0 13.32 18.51851851851852"
                                            style="max-height: 500px" width="13.32" height="18.51851851851852">
                                            <path fill="#4C4C4C"
                                                d="M0.12 11L2.58 7.568L4.296 5H6.168L3.78 8.348L2.016 11H0.12ZM4.428 11L2.688 8.348L0.276 5H2.148L3.864 7.568L6.312 11H4.428Z" />
                                            <path :fill="data.brand.whitelabel_config.fontColor"
                                                d="M16.1836 6.5C14.5195 6.5 13.1836 5.16406 13.1836 3.5C13.1836 1.85938 14.5195 0.5 16.1836 0.5C17.8242 0.5 19.1836 1.85938 19.1836 3.5C19.1836 5.16406 17.8242 6.5 16.1836 6.5ZM17.3555 7.625C19.6055 7.625 21.4336 9.45312 21.4336 11.7031C21.4336 12.1484 21.0586 12.5 20.6133 12.5H11.7305C11.2852 12.5 10.9336 12.1484 10.9336 11.7031C10.9336 9.45312 12.7383 7.625 14.9883 7.625H17.3555ZM31.1836 6.5C29.5195 6.5 28.1836 5.16406 28.1836 3.5C28.1836 1.85938 29.5195 0.5 31.1836 0.5C32.8242 0.5 34.1836 1.85938 34.1836 3.5C34.1836 5.16406 32.8242 6.5 31.1836 6.5ZM32.3555 7.625C34.6055 7.625 36.4336 9.45312 36.4336 11.7031C36.4336 12.1484 36.0586 12.5 35.6133 12.5H26.7305C26.2852 12.5 25.9336 12.1484 25.9336 11.7031C25.9336 9.45312 27.7383 7.625 29.9883 7.625H32.3555Z" />
                                        </svg>
                                    </template>

                                </div>

                                <div class="col-3  p-0 flight-cart-info" v-else>
                                    <template
                                        v-for="(addedPassenger, addedPassengerIndex) in addedService.addedPassengers"
                                        :key="addedPassengerIndex">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="9.620000000000001 0 13.32 18.51851851851852"
                                            style="max-height: 500px" width="13.32" height="18.51851851851852">
                                            <path fill="#4C4C4C"
                                                d="M0.12 11L2.58 7.568L4.296 5H6.168L3.78 8.348L2.016 11H0.12ZM4.428 11L2.688 8.348L0.276 5H2.148L3.864 7.568L6.312 11H4.428Z" />
                                            <path :fill="data.brand.whitelabel_config.fontColor"
                                                d="M16.1836 6.5C14.5195 6.5 13.1836 5.16406 13.1836 3.5C13.1836 1.85938 14.5195 0.5 16.1836 0.5C17.8242 0.5 19.1836 1.85938 19.1836 3.5C19.1836 5.16406 17.8242 6.5 16.1836 6.5ZM17.3555 7.625C19.6055 7.625 21.4336 9.45312 21.4336 11.7031C21.4336 12.1484 21.0586 12.5 20.6133 12.5H11.7305C11.2852 12.5 10.9336 12.1484 10.9336 11.7031C10.9336 9.45312 12.7383 7.625 14.9883 7.625H17.3555ZM31.1836 6.5C29.5195 6.5 28.1836 5.16406 28.1836 3.5C28.1836 1.85938 29.5195 0.5 31.1836 0.5C32.8242 0.5 34.1836 1.85938 34.1836 3.5C34.1836 5.16406 32.8242 6.5 31.1836 6.5ZM32.3555 7.625C34.6055 7.625 36.4336 9.45312 36.4336 11.7031C36.4336 12.1484 36.0586 12.5 35.6133 12.5H26.7305C26.2852 12.5 25.9336 12.1484 25.9336 11.7031C25.9336 9.45312 27.7383 7.625 29.9883 7.625H32.3555Z" />
                                        </svg>
                                    </template>

                                </div>
                                <RemovePassengerModalView :data="data" :cartItem="cartItem"
                                    :cartItemIndex="cartItemIndex" :addedService="addedService"
                                    :addedServiceIndex="addedServiceIndex" />
                                <div class="col-7 service-name p-0 flight-cart-info"
                                    :style="{ color: data.brand.whitelabel_config.fontColor2 }">
                                    {{ data.serviceTexts[addedService.type].attribute_added }}
                                </div>
                                <div class="col-2 service-price p-0 flight-cart-info"
                                    :style="{ color: data.brand.whitelabel_config.buttonBackgroundColor}">
                                    {{ getServicePassengersPrice(addedService.addedPassengers) }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-1 p-0 text-end" data-bs-target="#flightCarousel" data-bs-slide="next">
                <div class="flight-right-cart">

                </div>
            </div>
        </div>
    </template>



    <div class="total-cart-container row">
        <div class="col-1 p-0">
            <div class="flight-left-cart" data-bs-target="#flightCarousel" data-bs-slide="prev">
            </div>
        </div>
        <div class="col-10 p-0">
            <div class="flight-cart" :style="{ background: data.brand.whitelabel_config.cartBackgroundColor }">
                <div class="flight-info">
                    <div class="row">
                        <div class="col-7 total-text-container ps-0">
                            <span class="total-text" :style="{ color: data.brand.whitelabel_config.fontColor }">{{
                                data.text.total }}
                            </span>
                            <span class="vat-included" :style="{ color: data.brand.whitelabel_config.fontColor2 }">
                                ({{ data.text.vat_included }})</span>
                        </div>
                        <div class="col-5 cart-airport-flight">

                            <span class="total-price"
                                :style="{ color: data.brand.whitelabel_config.buttonBackgroundColor}">
                                {{
                                totalPriceWithVat
                                }}</span>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-1 p-0 text-end" data-bs-target="#flightCarousel" data-bs-slide="next">
            <div class="flight-right-cart">

            </div>
        </div>
    </div>


</template>

<script>
import RemovePassengerModalView from "./RemovePassengerModalView.vue";
import { mapActions, mapGetters } from "vuex";
export default {
    components: {
        RemovePassengerModalView,
    },

    props: ['data', 'journeys', 'moment', 'cartItems'],

    computed: {
        ...mapGetters(['getServicePassengersPrice', 'totalPriceWithVat']),
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        ...mapActions(['loadData', 'previousStep']),
    },



}
</script>

<style scoped>
.vat-included {
    /* font-family: Figtree; */
    font-size: 12px;
    font-weight: 700;
    line-height: 14.4px;
    text-align: left;
    display: flex;
    align-items: center;
    color: #4C4C4C;
    padding: 3px 5px 0px 5px;
}

.total-text-container {
    display: flex;
    align-items: center;
}

.total-price {
    /* font-family: Figtree; */
    font-size: 30px;
    font-weight: 800;
    line-height: 36px;
    text-align: center;
}

.total-text {
    /* font-family: Figtree; */
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #000000;
}


.total-cart-container {
    margin: 40px 0px 40px 0px;
}

.service-price {
    /* font-family: Figtree; */
    font-size: 12px;
    font-weight: 700;
    line-height: 14.4px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.service-name {
    /* font-family: Figtree; */
    font-size: 12px;
    font-weight: 700;
    line-height: 14.4px;
    text-align: left;
    display: flex;
    align-items: center;
    color: #4C4C4C;

}


.flight-cart-info {
    border-bottom: 0.5px solid #999999;
    margin-top: 30px;
}

.airline-svg {
    margin: 0px 3px 2px 3px
}

.cart-airport-flight {
    /* font-family: Figtree; */
    font-size: 14.57px;
    font-weight: 800;
    line-height: 17.49px;
    text-align: left;
    color: #000000;
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding: 0px;
    justify-content: flex-end;
}

.cart-airport-flight[aria-expanded="false"] .chevron-down svg {
    transform: rotate(180deg);
}

.chevron-down {
    padding-bottom: 6px;
    padding-left: 24px;

}

.airline-info {
    /* min-width: 117px; */
    padding: 0px 15px 0px 15px;
    height: 29.71px;
    border-radius: 10px;
    background-color: #000;
    color: #FFFFFF;
    border: none;
    outline: none;
    cursor: pointer;
    /* font-family: Figtree; */
    font-size: 12px;
    font-weight: 800;
    line-height: 14.4px;
    text-align: center;

}

.flight-info {
    padding: 21px 22px 21px 22px;
}

.flight-cart {
    min-height: 67px;
    border-radius: 25px;
    background-color: #ffffff;
    padding: 0px 12px 0px 12px;
}

.flight-left-cart {
    margin-top: 11px;
    /* height: 163px; */
    border-radius: 0px 22px 22px 0px;
    margin-right: 8px;
}

.flight-cart-container {
    margin: 15px 0px 0px 0px;
}

.svg-knob-elevation-and-text {
    margin-top: 30px;
    display: inline-flex;
}

.your-cart-text {
    padding-left: 16px;
    /* font-family: Figtree; */
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    display: flex;
    align-items: center;
    color: #000000;
}
</style>